import React from 'react';
import '../assets/css/Footer.css';
import logo from '../assets/images/logo.png';
import { FaMapMarkerAlt, FaEnvelope, FaClock } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-3 mb-4">
            <Link to="/"><img src={logo} alt="Safari Technologies Logo" className="footer-logo" /></Link>
            <p>
              <strong>SAFARI TECHNOLOGIES</strong> est une ESN (Entreprise de Services du numérique) fondée en 2012.
            </p>
          </div>

          <div className="col-md-3 mb-4">
            <h5>Pages</h5>
            <ul className="list-unstyled">
              <li><Link to="/#definition">À propos de nous</Link></li>
              <li><Link to="/solutions/ApplicationsERP">Applications ERP/CRM</Link></li>
              <li><Link to="/solutions/Reseau">Réseau & Sécurité</Link></li>
              <li><Link to="/solutions/Systems">Systèmes</Link></li>
              <li><Link to="/solutions/ITCloud">Conseils IT & Cloud</Link></li>
              <li><Link to="/jobs">Nos Offres d'emploi</Link></li>
            </ul>
          </div>

          <div className="col-md-3 mb-4">
            <h5>Informations</h5>
            <ul className="list-unstyled">
              <li><Link to="/CeoMessage">Mot du Président</Link></li>
              <li><Link to="/privacy">Politique de confidentialité</Link></li>
              <li><Link to="/CGU">Conditions générales d'utilisation</Link></li>
              <li><Link to="/Politique-cookies">Politique relative aux cookies</Link></li>
            </ul>
          </div>

          <div className="col-md-3 mb-4">
            <h5>Contactez Nous</h5>
            <p><FaMapMarkerAlt /> 1-3 Place de la Berline 93200, Saint Denis</p>
            <p><FaClock /> Horaire d'ouverture: 9h - 18h</p>
            <p><FaEnvelope /> contact@safari-technologies.com</p>
            <div className="footer-social-icons">
              <a href="https://www.linkedin.com/company/safari-technologies/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            </div>
          </div>

          <div className="mb-4">
          <div className="iso-certification-footer">
            <img src={require('../assets/images/Logo-ISO-9001.png')} alt="ISO 9001:2015 Certification" className="iso-img-footer" />
            <a href={require('../assets/pdf/293616-41666 - Certificat initial (Cofrac et IAF).pdf')} target="_blank" rel="noopener noreferrer" className="iso-button-footer">
              Accéder à la certification
            </a>
          </div>
        </div>
        </div>

        


        <div className="footer-bottom text-center mt-4">
          <p>© Copyrights 2025 Developped by SAFARI Technologies</p>
          <p>Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
