import React, {useState,useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { FaHome } from "react-icons/fa";
import { FaRegFileAlt } from "react-icons/fa";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { FaMoneyBill } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import JobModal from "./JobModal";
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';





const JobDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [job, setJob] = useState(null); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const formatPublicationDate = (dateString) => {
    if (!dateString) return "Non spécifiée";
    const date = new Date(dateString);
    return `Il y a ${formatDistanceToNow(date, { locale: fr })}`;
  };

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(`https://intranet.safari-technologies.com/api/annonces/${id}`); 
        setJob(response.data); 
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch job details");
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [id]);

  if (loading) return <p>Loading job details...</p>;
  if (error) return <p>{error}</p>;
  if (!job) return <div>Offre d'emploi non trouvée</div>;

  return (
    <div>
      <Header />
      <div className="container p-5">
        <button
          className="btn btn-outline-secondary mb-4"
          onClick={() => navigate(-1)}
        >
          ← Retour aux offres
        </button>

        <div className="card-details shadow-sm">
          <div className="card-body p-4">
            <h1 className="job-title mb-4">{job.intitule || "Titre indisponible"}</h1>

            <div className="row mb-4">
              <div className="">
                <h5>Informations sur le poste</h5>
                <div className="d-flex flex-wrap gap-3">
                  <div className="info-item">
                    <FaLocationCrosshairs /> <strong>Localisation:</strong> {job.localisation || "N/A"}
                  </div>
                  <div className="info-item">
                    <FaRegFileAlt /> <strong>Type de contrat:</strong> {job.type_id || "N/A"}
                  </div>
                  <div className="info-item">
                    <FaMoneyBill /> <strong>Salaire:</strong> {job.salaire_annuel ? `${job.salaire_annuel} €` : "N/A"}
                  </div>
                  <div className="info-item">
                    <FaHome /> <strong>Télétravail:</strong> {job.teletravail ? "Oui" : "Non"}
                  </div>
                  {job.duree && (
                    <div className="info-item">
                      <FaClock /> <strong>Durée:</strong> {job.duree}
                    </div>
                  )}
                  <div className="info-item">
                    <strong>Date de publication:</strong> {formatPublicationDate(job.created_at)}
                  </div>
                </div>
              </div>
              <h5 className="d-flex justify-content-center mt-5 mb-5 job-desc">Description du poste</h5>

              <div
                className="description-content"
                dangerouslySetInnerHTML={{ __html: job.description || "Description indisponible" }}
              />


            <h5 className="d-flex mt-5 mb-5 job-desc">Missions:</h5>
            
            <div
                className="description-content"
                dangerouslySetInnerHTML={{ __html: job.missions || "Description indisponible" }}
              />
            
            <h5 className="d-flex mt-5 mb-5 job-desc">Compétences:</h5>
            
            <div
                className="description-content"
                dangerouslySetInnerHTML={{ __html: job.competences || "Description indisponible" }}
              />

            <h5 className="d-flex mt-5 mb-5 job-desc">Avantages:</h5>
            
            <div
                className="description-content"
                dangerouslySetInnerHTML={{ __html: job.avantages || "Description indisponible" }}
              />
            </div>

            

            <div className="d-flex justify-content-center">
              <button
                className="voir-btn"
                onClick={() => setShowModal(true)}
              >
                Postuler maintenant
              </button>
            </div>
          </div>
        </div>
      </div>

      <JobModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        jobTitle={job.intitule || "Poste"}
        jobId={job.id}
      />
      <Footer />
    </div>
  );
};

export default JobDetails;