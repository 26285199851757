import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Header.css';
import logo from '../assets/images/logo.png';
import iso from '../assets/images/iso_gold.jpg'
import { Link, useNavigate } from 'react-router-dom';


const searchIndex = [
  {keyword: "Définition: SAFARI Technologies", url:"/#definition"},
  { keyword: "Applications ERP/CRM", url: "/solutions/ApplicationsERP" },
  { keyword: "Systèmes", url: "/solutions/Systems" },
  { keyword: "Réseau", url: "/solutions/Reseau#p1" },
  {keyword: "Sécurité", url:"/solutions/Reseau#p2"},
  { keyword: "Conseils IT & Cloud", url: "/solutions/ITCloud" },
  { keyword: "Secteur Bancaire", url: "/secteurs/bancaire" },
  { keyword: "Secteur de l'industrie", url: "/secteurs/industries" },
  { keyword: "Secteur de services", url: "/secteurs/services" },



];




const Header = () => {

  const[searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const[suggestions, setSuggestions] = useState("");

  useEffect(() => {
    if (searchQuery) {
      const results = searchIndex.filter((item) =>
        item.keyword.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSuggestions(results);
    } else {
      setSuggestions([]);
    }
  }, [searchQuery]);

  const handleSearch = (e) => {
    e.preventDefault();

    const result = searchIndex.find((item) =>
      item.keyword.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (result) {
      navigate(result.url);
    } else {
      alert("Aucun résultat trouvé");
    }
  };


  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-light bg-white container-fluid">

        <div className="logo">
          <Link to="/"><img src={logo} alt="Safari Technologies Logo" /></Link>
        </div>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
            <form onSubmit={handleSearch} className='search-bar d-flex'>
              <input type="text" className="form-control bg-move" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
              <button type="submit" className="btn btn-light rounded-2">
                  <i className="fas fa-search"></i>
              </button>
            </form>

            {/* ISO Certification Badge - Positioned in the White Space */}
          <div className="iso-badge">
            <a href={require('../assets/pdf/293616-41666 - Certificat initial (Cofrac et IAF).pdf')} target="_blank" rel="noopener noreferrer" className='text-decoration-none'>
              <img src={iso} alt="ISO 9001:2015 Certified" className="iso-img"/>
              <span className="iso-text">Certifié ISO 9001:2015</span>
            </a>
          </div>

            {suggestions.length > 0 && (
              <ul className='search-suggestions'>
                {suggestions.map((suggestion, index) => (
                  <li key={index} className={index === 0 ? 'first-suggestion': ""} onClick={() => navigate(suggestion.url)}>
                    {suggestion.keyword}
                  </li>
                ))
                }
              </ul>
            )}



        <ul className="navbar-nav text-purple ms-auto text-purple">
            <li className="nav-item">
              <Link to="/#definition" className="nav-link">À propos de nous</Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#solutions"
                id="solutionsDropdown"
                role="button"
                aria-expanded="false"
              >
                Nos solutions
              </a>
              
              <ul className="dropdown-menu custom-dropdown" aria-labelledby="solutionsDropdown">
                <li><Link className="dropdown-item" to="/solutions/ApplicationsERP"><i className="fas fa-cogs" style={{ marginRight: '5px' }}></i> Applications ERP/CRM</Link></li>
                <li><Link className="dropdown-item" to="/solutions/Reseau"><i className="fas fa-shield-alt" style={{ marginRight: '5px' }}></i> Réseau & Sécurité</Link></li>
                <li><Link className="dropdown-item" to="/solutions/Systems"><i className="fas fa-desktop" style={{ marginRight: '5px' }}></i> Systèmes</Link></li>
                <li><Link className="dropdown-item" to="/solutions/ITCloud"><i className="fas fa-cloud" style={{ marginRight: '5px' }}></i> Conseils IT & Cloud</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="/jobs"className='nav-link'>Nos offres d'emploi</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">Contact</Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
