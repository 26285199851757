import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/CarouselCards.css';
import { Link } from 'react-router-dom';


const CarouselWithCards = () => {
  return (
    <div className="carousel-cards-container container-fluid">
      <div className="row">
        
        {/* Carousel Section */}
        <div className="col-lg-8 carousel-section" data-aos="fade-down">
          <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="carousel-content text-white c1">
                  <h2>Bienvenue Chez Safari Technologies!</h2>
                  <p className='p-5'>Des conseils à l'échelle humaine. Optez pour une société de services informatiques proche de ses clients et de ses consultants !</p>
                </div>
              </div>

              <div className="carousel-item">
                <div className="carousel-content text-white c2">
                  <h2>Expertise et Innovation</h2>
                  <p className='p-5'>Nous fournissons des solutions sur mesure pour répondre aux besoins uniques de chaque client.</p>
                </div>
              </div>

              <div className="carousel-item">
                <div className="carousel-content text-white c3">
                  <h2>Une expertise digitale à votre service</h2>
                  <p className='p-5'>Chez Safari Technologies, nous mettons l'innovation et la performance au cœur de votre transformation numérique, avec des solutions sur mesure adaptées à vos besoins.</p>
                </div>
              </div>

              <div className="carousel-item">
                <div className="carousel-content text-white c5">
                  <h2>Certification et Excellence</h2>
                  <p className='p-5'>
                    Safari Technologies est fière d'annoncer sa certification ISO 9001:2015, gage de notre engagement envers l'excellence et la satisfaction client. Cette reconnaissance témoigne de notre rigueur et de notre capacité à fournir des solutions innovantes et sur mesure.
                  </p>
                </div>
              </div>


              <div className="carousel-item">
                <div className="carousel-content text-white carousel-big c4">
                  <h2>Notre Vision et Nos Convictions</h2>
                  <p className='parag-big'>« Nous avons la conviction d’être les meilleurs dans nos domaines d’expertise et de responsabilité. Cette idée,
                    associée à une organisation interne exemplaire, une détermination sans faille est la force même de 
                    SAFARI TECHNOLOGIES. » <span className="carousel-item-author author">- Nacer Acherchour - CEO</span>
                  </p>
                  <Link to="/CeoMessage"><button className="lire-plus">Lire Plus</button></Link>
                </div>
              </div>

            </div>

            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>

            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>

          </div>
        </div>

        {/* Cards Section */}
        <div className="col-lg-4 cards-section d-flex flex-column align-items-center justify-content-around" data-aos="fade-left">
          <a className="text-decoration-none text-white custom-card card1" href="#definition">À propos de nous</a>
          <a className="text-decoration-none text-white custom-card card2" href="#structure">Nos Structures</a>
          <a className="text-decoration-none text-white custom-card card2" href="/jobs">Nos Offres d'emploi</a>
        </div>
        
      </div>
    </div>
  );
};

export default CarouselWithCards;